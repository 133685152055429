<template>
  <v-list-item @click="$emit('click')">
    <v-list-item-content>
      <v-list-item-title>
        <span v-if="!firstWordBold" class="body-1 grey--text text--darken-3">
          {{ title }}
        </span>
        <div v-if="firstWordBold">
          <b>
            {{ title.split(" ")[0] }}
          </b>
          <span class="body-1 grey--text text--darken-3">
            {{ title.split(" ").slice(1).join(" ") }}
          </span>
        </div>
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-icon class="align-self-center">
      <v-icon>open_in_new</v-icon>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
    firstWordBold: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
